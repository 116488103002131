import axios from 'axios';

import { getAllStoreInfoUrl } from '../constants';
import { KdsStoreDetailsResDto, KdsStoreResDto } from '../submodules/sicpama-shared';

import BaseHttpService from './base-http.service';
import { getDefaultAxiosConfig, handleHttpResult, HttpResult } from './http';
import { loginService } from './login.service';
import { GetTokenService } from './storage.service';

class StoreService extends BaseHttpService {
  async getStore(storeId: number): Promise<KdsStoreResDto> {
    if (loginService.getStaffToken() === undefined) {
      return null;
    }
    const response = await this.get<KdsStoreResDto>(getAllStoreInfoUrl(storeId));
    return response.data;
  }

  async editStore(storeId: number, data: Partial<KdsStoreDetailsResDto>): Promise<void> {
    await this.put(`kds/stores/${storeId}`, data);
  }

  async bulkUpdateMenusAvailability(
    storeId: number,
    menuIds: number[],
    isAvailable: boolean,
  ): Promise<void> {
    await this.put(`stores/${storeId}/menus/bulk-update-availability`, {
      data: menuIds.map((id) => ({ id })),
      isAvailable,
    });
  }
}

export const httpGetStore =
  (getToken: GetTokenService) =>
  async (storeId: number): Promise<HttpResult<KdsStoreResDto>> => {
    const result = await axios.get(
      `v1/kds/stores/${storeId}/all-store-info`,
      getDefaultAxiosConfig(getToken),
    );
    return handleHttpResult(result);
  };

export const httpAddStoreAttribute =
  (getToken: GetTokenService) =>
  async (storeId: number, data: any): Promise<HttpResult<KdsStoreResDto>> => {
    const result = await axios.post(
      `v1/kds/stores/${storeId}/attributes`,
      data,
      getDefaultAxiosConfig(getToken),
    );
    return handleHttpResult(result);
  };
export const httpUpdateStoreAttribute =
  (getToken: GetTokenService) =>
  async (storeId: number, attributeId: any, data: any): Promise<HttpResult<KdsStoreResDto>> => {
    const result = await axios.put(
      `v1/kds/stores/${storeId}/attributes/${attributeId}`,
      data,
      getDefaultAxiosConfig(getToken),
    );
    return handleHttpResult(result);
  };

export const storeService = new StoreService();
