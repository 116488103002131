import { getFingerprint as generateFingerprint } from '@thumbmarkjs/thumbmarkjs';
import { HttpResult } from 'src/services/http';
import { getToken } from 'src/services/storage.service';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { httpGetStore } from '../../services';
import { KdsStoreResDto, STORE_ATTRIBUTE_NAME } from '../../submodules/sicpama-shared';

interface StoreState {
  storeData: KdsStoreResDto;
  fingerPrint: string;
  isDesktopNavbarExpanded: boolean;
  getStoreData: (storeId: number) => Promise<HttpResult<KdsStoreResDto>>;
  getFingerPrint: () => Promise<string>;
  setDesktopNavbarExpanded: (expanded: boolean) => void;
}

export const useStoreState = create<StoreState>()(
  persist(
    (set, get) => ({
      storeData: {} as KdsStoreResDto,
      fingerPrint: '',
      isDesktopNavbarExpanded: false,
      getStoreData: async (id: number) => {
        const result = await httpGetStore(getToken)(id);
        if (result.success) {
          set({ storeData: result.data });
        }
        return result;
      },
      getFingerPrint: async () => {
        const currentFingerPrint = get().fingerPrint;
        if (!currentFingerPrint) {
          try {
            // Use a free FingerprintJS  alternative, can be replaced at anytime later
            // Let all the traffic of FingerprintJS Pro for diner side ($99 / 20000 API calls per month)
            const newFingerPrint = await generateFingerprint();
            set({ fingerPrint: newFingerPrint });
            return newFingerPrint;
          } catch (error) {
            console.error('Error_generating_fingerPrint', error);
            return '';
          }
        }
        return currentFingerPrint;
      },
      setDesktopNavbarExpanded: (expanded) => {
        set({ isDesktopNavbarExpanded: expanded });
      },
    }),
    {
      name: 'store-storage',
      partialize: (state) => ({ fingerPrint: state.fingerPrint }),
    },
  ),
);

const getAttribute = (name: STORE_ATTRIBUTE_NAME) => (state: StoreState) =>
  state.storeData.attributes?.find((attr) => attr.name === name);

export const StoreSelector = {
  getAttribute,
};
