import { QuantitySelector } from '@sicpama/core-components';
import { Popover, Switch } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useChangeBusinessAvailability from '../../hooks/useChangeBusinessAvailability';

import './styles/PauseBusiness.scss';

dayjs.extend(utc);

const PauseBusiness = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(10);
  const [pausePopoverOpen, setPausePopoverOpen] = useState(false);

  const { isEditing, changeAvailability, isPausing } = useChangeBusinessAvailability();

  const closePopover = () => {
    setPausePopoverOpen(false);
  };

  const containerClassName = useMemo(() => {
    return isPausing ? 'pause-business-switch-container' : 'non-pause-business-switch-container';
  }, [isPausing]);

  return (
    <Popover
      content={
        <div>
          <div className="w-[150px]">
            <QuantitySelector
              value={value}
              onChange={(e: number) => {
                setValue(e);
              }}
              max={600}
              sx={{ maxWidth: 200 }}
            />
          </div>
          <div className="grid grid-cols-2 -mx-3 -mb-3 mt-2 h-[42px] rounded-[8px] overflow-hidden">
            <div
              className={`flex items-center justify-center text-center font-semibold bg-[#FC5C0C] text-white ${
                isEditing && 'opacity-50'
              }`}
              onClick={async () => {
                await changeAvailability({ isClosed: true, value, callback: closePopover });
              }}
            >
              {t('common.start')}
            </div>
            <div
              className="flex items-center justify-center text-center font-semibold bg-[#9A9A9A] text-black"
              onClick={closePopover}
            >
              {t('common.cancel')}
            </div>
          </div>
        </div>
      }
      open={pausePopoverOpen}
      trigger="click"
    >
      <Switch
        checkedChildren={
          <span className="text-black text-[14px] leading-[24px]">{t('food-truck.pause')}</span>
        }
        unCheckedChildren={
          <span className="text-black text-[14px] leading-[24px]">{t('food-truck.pause')}</span>
        }
        className={`${containerClassName} !bg-white [&_[class*='ant-switch-inner-unchecked']]:!mt-[-23px] [&_[class*='ant-switch-handle']]:top-[5px] [&_[class*='ant-switch-handle']]:left-[10px] shadow h-7 w-[98px] !rounded-[10px]`}
        checked={isPausing}
        onClick={() => setPausePopoverOpen(true)}
      />
    </Popover>
  );
};
export default PauseBusiness;
