/* eslint-disable @typescript-eslint/no-unused-vars */
import { notifications } from '@sicpama/core-components/lib/notifications';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';

import { storeService } from '../services';
import { useStoreState } from '../States';

const useChangeBusinessAvailability = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { storeData, getStoreData } = useStoreState((state) => state);
  const isPausing = useMemo(() => {
    const { isOpen, openTime } = storeData;
    return !isOpen && dayjs(openTime).utc().format('YYYY-MM-DD HH:mm:ss') !== '2999-12-31 23:59:59';
  }, [storeData]);
  const changeAvailability = async ({
    value,
    isClosed = false,
    callback,
  }: {
    value?: any;
    isClosed?: boolean;
    callback: any;
  }) => {
    if (isEditing) {
      return;
    }
    setIsEditing(true);

    try {
      const {
        attributes,
        receiptPrinters,
        isOpen,
        paymentGatewayInfo,
        snsNotificationTemplate,
        coupons,
        ..._storeData
      } = storeData;

      // Pause the store
      if (isClosed && value) {
        const addedOpenTime = dayjs().add(value, 'minute');
        const openTimestamp = addedOpenTime.valueOf(); // in seconds
        const openTime = addedOpenTime.utc().format('YYYY-MM-DD HH:mm:ss');

        await storeService.editStore(storeData.id, {
          ..._storeData,
          openTime,
        });
        localStorage?.setItem(`${storeData.id}_openTime`, openTimestamp.toString());
        setTimeout(() => {
          localStorage?.removeItem(`${storeData.id}_openTime`);
          getStoreData(storeData.id);
        }, value * 60 * 1000);
      }

      // Close the store permanently
      if (isClosed && !value) {
        await storeService.editStore(storeData.id, {
          ..._storeData,
          openTime: '2999-12-31 23:59:59',
        });
      }

      // Open the store
      if (!isClosed) {
        await storeService.editStore(storeData.id, {
          ..._storeData,
          openTime: null,
        });
      }

      await getStoreData(storeData.id);
      callback?.();
    } catch (error) {
      notifications.show({
        withCloseButton: true,
        autoClose: 2000,
        message: error?.message || JSON.stringify(error),
        color: 'red',
      });
    }
    setIsEditing(false);
  };

  return {
    isEditing,
    changeAvailability,
    isOpen: storeData.isOpen,
    openTime: storeData.openTime,
    isPausing,
  };
};

export default useChangeBusinessAvailability;
