import React from 'react';

const IconFirst = () => (
  <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.5 10L13.5 4.5V15.5L6.5 10ZM5 4.5H7V15.5H5V4.5Z" fill="currentColor" />
  </svg>
);

const IconPrev = () => (
  <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 4.5L5.5 10L12.5 15.5V4.5Z" fill="currentColor" />
  </svg>
);

const IconNext = () => (
  <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 4.5L14.5 10L7.5 15.5V4.5Z" fill="currentColor" />
  </svg>
);

const IconLast = () => (
  <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5 10L6.5 15.5V4.5L13.5 10ZM15 4.5V15.5H13V4.5H15Z" fill="currentColor" />
  </svg>
);

interface TablePaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const TablePagination: React.FC<TablePaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const getVisiblePages = () => {
    const delta = 2;
    const range: (number | string)[] = [];
    const rangeWithDots: (number | string)[] = [];
    let l: number | undefined;

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(currentPage - i) <= delta) {
        range.push(i);
      }
    }

    range.forEach((i) => {
      if (l) {
        if (Number(i) - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (Number(i) - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = Number(i);
    });

    return rangeWithDots;
  };

  return (
    <nav className="flex items-center justify-center px-4" aria-label="Pagination">
      <div className="flex flex-col items-center gap-4">
        <div className="text-sm text-gray-700">
          <span className="font-medium">Page {currentPage}</span> of{' '}
          <span className="font-medium">{totalPages}</span>
        </div>

        <ul className="flex items-center gap-2">
          <li>
            <button
              onClick={() => onPageChange(1)}
              disabled={currentPage === 1}
              className="flex items-center gap-1 px-3 py-2 rounded-lg border border-gray-200 bg-white text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
              aria-label="Go to first page"
            >
              <IconFirst />
              <span className="hidden sm:inline text-sm">First</span>
            </button>
          </li>

          <li>
            <button
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="flex items-center gap-1 px-3 py-2 rounded-lg border border-gray-200 bg-white text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
            >
              <IconPrev />
              <span className="hidden sm:inline text-sm">Previous</span>
            </button>
          </li>

          <li className="flex items-center gap-1">
            {getVisiblePages().map((page, index) => (
              <React.Fragment key={index}>
                {page === '...' ? (
                  <span className="px-3 py-2 text-gray-500">...</span>
                ) : (
                  <button
                    onClick={() => onPageChange(Number(page))}
                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors
                      ${
                        currentPage === page
                          ? 'bg-blue-50 text-blue-600 border-blue-200 border'
                          : 'text-gray-600 hover:bg-gray-50 border border-gray-200'
                      }`}
                    aria-current={currentPage === page ? 'page' : undefined}
                  >
                    {page}
                  </button>
                )}
              </React.Fragment>
            ))}
          </li>

          <li>
            <button
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="flex items-center gap-1 px-3 py-2 rounded-lg border border-gray-200 bg-white text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
            >
              <span className="hidden sm:inline text-sm">Next</span>
              <IconNext />
            </button>
          </li>

          <li>
            <button
              onClick={() => onPageChange(totalPages)}
              disabled={currentPage === totalPages}
              className="flex items-center gap-1 px-3 py-2 rounded-lg border border-gray-200 bg-white text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
            >
              <span className="hidden sm:inline text-sm">Last</span>
              <IconLast />
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default TablePagination;
