// Example: src/pages/SuccessPage.tsx
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

// or use your own way of reading URL query strings

function SuccessPage() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const authKey = searchParams.get('authKey');
    const customerKey = searchParams.get('customerKey');
    // Possibly other query params. Then confirm or store them:
    console.log('Billing authKey:', authKey);
    console.log('Billing customerKey:', customerKey);
    // 1) If needed, POST to your server to finalize the billingKey issuance:
    //    await myServerApi.confirmBillingKey({ authKey, customerKey });
  }, [searchParams]);

  return <div>결제가 성공적으로 완료되었습니다!</div>;
}

export default SuccessPage;
