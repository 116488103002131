import { notifications } from '@sicpama/core-components/lib/notifications';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import queryString from 'query-string';

import { loginService } from './login.service';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1`,
  timeout: 30000,
  paramsSerializer: (params) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const filteredParams = Object.fromEntries(Object.entries(params).filter(([_k, v]) => v));
    return queryString.stringify(filteredParams, { arrayFormat: 'bracket' });
  },
});

export default class BaseHttpService {
  addAuthHeader(options: AxiosRequestConfig): AxiosRequestConfig {
    if (loginService.getStaffToken() === null) {
      return options;
    }
    return {
      ...options,
      headers: {
        Authorization: 'Bearer ' + loginService.getStaffToken(),
        'store-fingerprint':
          JSON.parse(localStorage.getItem('store-storage') || '{}')?.state?.fingerPrint || '',
      },
    };
  }

  async get<T>(endpoint: string, options = {}): Promise<AxiosResponse<T>> {
    return await instance
      .get<T>(`/${endpoint}`, this.addAuthHeader(options))
      .catch((error) => this.handleHttpError(error));
  }

  async post<T>(endpoint: string, data = {}, options = {}): Promise<AxiosResponse<T>> {
    return await instance
      .post<T>(`/${endpoint}`, data, this.addAuthHeader(options))
      .catch((error) => this.handleHttpError(error));
  }

  async postWithoutToken<T, D>(endpoint: string, data: D, options = {}): Promise<AxiosResponse<T>> {
    return await instance
      .post(`/${endpoint}`, data, options)
      .catch((error) => this.handleHttpError(error));
  }

  async put(endpoint: string, data = {}, options = {}): Promise<AxiosResponse> {
    return await instance
      .put(`/${endpoint}`, data, this.addAuthHeader(options))
      .catch((error) => this.handleHttpError(error));
  }

  async delete(endpoint: string, options = {}): Promise<AxiosResponse> {
    return await instance
      .delete(`/${endpoint}`, this.addAuthHeader(options))
      .catch((error) => this.handleHttpError(error));
  }

  async patch(endpoint: string, data = {}, options = {}): Promise<AxiosResponse> {
    return await instance
      .patch(`/${endpoint}`, data, this.addAuthHeader(options))
      .catch((error) => this.handleHttpError(error));
  }

  handleHttpError(error: any): any {
    const statusCode = error?.response?.status;
    const { language } = navigator;
    // For posthog track
    console.log('errorRes: ', error?.response?.data);
    if (axios.isAxiosError(error) && !error?.response?.data) {
      error = {
        ...error,
        statusCode: error?.response?.status,
        message: error.message,
      };
    }
    if (statusCode === 401 || statusCode === 403 || statusCode === 500) {
      // window.location.href = `${window.location.origin}/login`;
    } else {
      const errorMassage = error?.response?.data?.errorMessage || 'Error';
      notifications.show({
        title: errorMassage,
        message: language.startsWith('ko')
          ? '불편을 끼쳐드려 죄송합니다!'
          : 'Sorry For This Inconvenience!',
        radius: 'md',
        color: 'red',
        autoClose: process.env.REACT_APP_AUTO_CLOSE_ERROR_MS
          ? +process.env.REACT_APP_AUTO_CLOSE_ERROR_MS
          : 5000,
      });
    }
  }
}
