import React from 'react';
import './CrmDemoPage.scss';
import { useStoreState } from 'src/States/Store/store'; // Update path as needed

import {
  CrmAnalyticsResponse,
  CrmAnalyticsRow,
  crmAnalyticsService,
} from '../../services/crm-analytics.service';

import TablePagination from './TablePagination';

const CrmDemoPage: React.FC = () => {
  const [rows, setRows] = React.useState<CrmAnalyticsRow[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  // Pagination states
  const [page, setPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [total, setTotal] = React.useState<number>(0);

  // "Select All" checkbox
  const [selectAll, setSelectAll] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState<number[]>([]);

  // Example: your storeId from global state or context
  const storeId = useStoreState((state) => state.storeData.id);

  // 1) Fetch data (with pagination)
  const fetchData = React.useCallback(
    async (newPage: number, newPageSize: number) => {
      if (!storeId) return;
      setLoading(true);

      try {
        const { data, page, pageSize, total }: CrmAnalyticsResponse =
          await crmAnalyticsService.getCrmAnalytics(storeId, newPage, newPageSize);

        setRows(data);
        setPage(page);
        setPageSize(pageSize);
        setTotal(total);

        // Reset checkboxes
        setSelectAll(false);
        setSelectedRows([]);
      } catch (error) {
        console.error('Error fetching CRM analytics:', error);
      } finally {
        setLoading(false);
      }
    },
    [storeId],
  );

  // 2) On mount & whenever storeId changes, load page=1
  React.useEffect(() => {
    fetchData(1, pageSize);
  }, [storeId, pageSize, fetchData]);

  // 3) Handle "Select All"
  const handleSelectAll = () => {
    const nextSelectAll = !selectAll;
    setSelectAll(nextSelectAll);

    if (nextSelectAll) {
      // Mark all row indices as selected
      setSelectedRows(rows.map((_, idx) => idx));
    } else {
      setSelectedRows([]);
    }
  };

  // 4) Handle individual row checkbox
  const handleCheckboxChange = (rowIndex: number) => {
    let nextSelectedRows = [...selectedRows];
    if (nextSelectedRows.includes(rowIndex)) {
      nextSelectedRows = nextSelectedRows.filter((i) => i !== rowIndex);
    } else {
      nextSelectedRows.push(rowIndex);
    }
    setSelectedRows(nextSelectedRows);
    setSelectAll(nextSelectedRows.length === rows.length);
  };

  // 5) "Reach Out" button
  const handleReachOut = () => {
    console.log('Reaching out to row indices:', selectedRows);
    alert(`Reaching out to ${selectedRows.length} selected customers!`);
  };

  if (loading) {
    return <div className="crm-demo-page">Loading CRM data...</div>;
  }

  return (
    <div className="crm-demo-page">
      {/* Example statistic cards */}
      <div className="wireframe-cards">
        <div className="statistic-card card-1">
          <div className="card-top-container">
            <div className="avatar-description">
              <div className="category-tab money-icon" />
              <div className="category-tab-title">Credit</div>
            </div>
          </div>
          <div className="card-text-block">
            <div className="card-headline">$57 used / 21 people</div>
            <div className="card-data">
              <div className="percent-change negative">-10.2%</div>
              <div className="from-last-month">from last month</div>
            </div>
          </div>
        </div>

        <div className="statistic-card card-2">
          <div className="card-top-container">
            <div className="avatar-description">
              <div className="category-tab leader-icon" />
              <div className="category-tab-title">Key Diner</div>
            </div>
          </div>
          <div className="card-text-block">
            <div className="card-headline">9 people</div>
            <div className="card-data">
              <div className="percent-change positive">+13.2%</div>
              <div className="from-last-month">from last month</div>
            </div>
          </div>
        </div>

        <div className="statistic-card card-3">
          <div className="card-top-container">
            <div className="avatar-description">
              <div className="category-tab promote-icon" />
              <div className="category-tab-title">Brought</div>
            </div>
          </div>
          <div className="card-text-block">
            <div className="card-headline">$34,078 / 12 people</div>
            <div className="card-data">
              <div className="percent-change positive">+13.2%</div>
              <div className="from-last-month">from last month</div>
            </div>
          </div>
        </div>

        <div className="statistic-card card-4">
          <div className="card-top-container">
            <div className="avatar-description">
              <div className="category-tab investment-icon" />
              <div className="category-tab-title">ROI</div>
            </div>
          </div>
          <div className="card-text-block">
            <div className="card-headline">2,068 %</div>
            <div className="card-data">
              <div className="percent-change positive">+13.2%</div>
              <div className="from-last-month">from last month</div>
            </div>
          </div>
        </div>
      </div>

      {/* "Reach Out" button */}
      <div className="table-actions">
        <button className="btn-reach-out" onClick={handleReachOut}>
          Reach Out
        </button>
      </div>

      {/* Outer container with pagination fixed below */}
      <div className="component-6">
        {/* Scrollable table */}
        <div className="table-scroll-wrapper">
          <div className="table-container">
            {/* Table header row */}
            <div className="table-header-n3">
              {/* 1. Master checkbox */}
              <div className="check header-col">
                <input
                  type="checkbox"
                  className="checkbox-square"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </div>

              {/* 2. Customer (maskedName) */}
              <div className="header-col">Customer</div>

              {/* 3. Date of Birth */}
              <div className="header-col">Date of Birth</div>

              {/* 4. visitCount */}
              <div className="header-col">Visits</div>

              {/* 5. accompaniedVisits */}
              <div className="header-col">Accompanied Visits</div>

              {/* 6. averageOrderValue */}
              <div className="header-col">Avg Order</div>

              {/* 7. totalSpent */}
              <div className="header-col">Total Spent</div>

              {/* 8. totalSpentWithAccompanied */}
              <div className="header-col">Spent w/ Accomp</div>

              {/* 9. lastVisit */}
              <div className="header-col">Last Visit</div>

              {/* 10. daysSinceLastVisit */}
              <div className="header-col">Days Since Last</div>

              {/* 11. favoriteCategory */}
              <div className="header-col">Favorite Category</div>

              {/* 12. typicalVisitDay */}
              <div className="header-col">대표방문요일</div>

              {/* 13. typicalVisitHour */}
              <div className="header-col">대표방문시간대</div>

              {/* 14. averageGroupSize */}
              <div className="header-col">평균동반인원수</div>

              {/* 15. timesUsedDiscount */}
              <div className="header-col">할인사용횟수</div>

              {/* 16. uniqueCategoriesTried */}
              <div className="header-col">주문한카테고리수</div>

              {/* 17. recommendedCoupon */}
              <div className="header-col">추천쿠폰</div>

              {/* 18. bestSendTime */}
              <div className="header-col">최적발송시간</div>

              {/* 19. couponValidity */}
              <div className="header-col">쿠폰유효기간</div>
            </div>

            {/* Render each row */}
            {rows.map((item, idx) => {
              const isChecked = selectedRows.includes(idx);
              return (
                <div className="table-row-2" key={idx}>
                  {/* Checkbox col */}
                  <div className="check row-col">
                    <input
                      type="checkbox"
                      className="checkbox-square"
                      checked={isChecked}
                      onChange={() => handleCheckboxChange(idx)}
                    />
                  </div>

                  {/* 2. maskedName */}
                  <div className="row-col table-cell-customer">
                    <div className="users-photo" />
                    <div className="customer-name">{item.maskedName}</div>
                  </div>

                  {/* 3. dateOfBirth */}
                  <div className="row-col">{item.dateOfBirth ?? ''}</div>

                  {/* 4. visitCount */}
                  <div className="row-col">{item.visitCount}</div>

                  {/* 5. accompaniedVisits */}
                  <div className="row-col">{item.accompaniedVisits}</div>

                  {/* 6. averageOrderValue */}
                  <div className="row-col">{item.averageOrderValue}</div>

                  {/* 7. totalSpent */}
                  <div className="row-col">{item.totalSpent}</div>

                  {/* 8. totalSpentWithAccompanied */}
                  <div className="row-col">{item.totalSpentWithAccompanied}</div>

                  {/* 9. lastVisit */}
                  <div className="row-col">{item.lastVisit ?? ''}</div>

                  {/* 10. daysSinceLastVisit */}
                  <div className="row-col">
                    {item.daysSinceLastVisit !== null ? `${item.daysSinceLastVisit} days ago` : ''}
                  </div>

                  {/* 11. favoriteCategory */}
                  <div className="row-col">{item.favoriteCategory ?? ''}</div>

                  {/* 12. typicalVisitDay */}
                  <div className="row-col">{item.typicalVisitDay ?? ''}</div>

                  {/* 13. typicalVisitHour */}
                  <div className="row-col">{item.typicalVisitHour ?? ''}</div>

                  {/* 14. averageGroupSize */}
                  <div className="row-col">{item.averageGroupSize ?? 0}</div>

                  {/* 15. timesUsedDiscount */}
                  <div className="row-col">{item.timesUsedDiscount ?? 0}</div>

                  {/* 16. uniqueCategoriesTried */}
                  <div className="row-col">{item.uniqueCategoriesTried ?? 0}</div>

                  {/* 17. recommendedCoupon */}
                  <div className="row-col">{item.recommendedCoupon ?? ''}</div>

                  {/* 18. bestSendTime */}
                  <div className="row-col">{item.bestSendTime ?? ''}</div>

                  {/* 19. couponValidity */}
                  <div className="row-col">{item.couponValidity ?? ''}</div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Pagination fixed at bottom (outside scroll) */}
        <div className="table-pagination-container">
          <TablePagination
            currentPage={page}
            totalPages={Math.ceil(total / pageSize)}
            onPageChange={(newPage) => fetchData(newPage, pageSize)}
          />
        </div>
      </div>
    </div>
  );
};

export default CrmDemoPage;
