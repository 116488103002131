// src/services/crm-analytics.service.ts

import { getCrmAnalyticsUri } from '../constants/crm.constants';

import BaseHttpService from './base-http.service';

/**
 * Matches the backend’s CrmAnalyticsDomain fields
 */
export interface CrmAnalyticsRow {
  maskedName: string;
  dateOfBirth: string | null;
  visitCount: number;
  accompaniedVisits: number;
  averageOrderValue: number;
  totalSpent: number;
  totalSpentWithAccompanied: number;
  lastVisit: string | null;
  daysSinceLastVisit: number | null;
  favoriteCategory: string | null;
  typicalVisitDay: string | null;
  typicalVisitHour: string | null;
  averageGroupSize: number | null;
  timesUsedDiscount: number | null;
  uniqueCategoriesTried: number | null;
  recommendedCoupon: string | null;
  bestSendTime: string | null;
  couponValidity: string | null;
}

/**
 * The backend’s shape for paginated results:
 * { data: CrmAnalyticsRow[], page, pageSize, total }
 */
export interface CrmAnalyticsResponse {
  data: CrmAnalyticsRow[];
  page: number;
  pageSize: number;
  total: number;
}

/**
 * Service class that calls our backend’s CRM analytics endpoint
 * with optional ?page & ?pageSize query params.
 */
class CrmAnalyticsService extends BaseHttpService {
  async getCrmAnalytics(storeId: number, page = 1, pageSize = 10): Promise<CrmAnalyticsResponse> {
    // We pass page & pageSize as query params
    const response = await this.get<CrmAnalyticsResponse>(getCrmAnalyticsUri(storeId), {
      params: { page, pageSize },
    });
    return response.data;
  }
}

export const crmAnalyticsService = new CrmAnalyticsService();
