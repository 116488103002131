import styled from '@emotion/styled';
import { Icon, Select, Accordion } from '@sicpama/core-components';
import { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import DatabaseIcon from 'src/components/Icons/database';
import HomeIcon from 'src/components/Icons/home';
import { IconProps } from 'src/components/Icons/Icon.interface';
import InventoryIcon from 'src/components/Icons/inventory';
import MenuIcon from 'src/components/Icons/menu';
import PrinterIcon from 'src/components/Icons/printer';
import ReportIcon from 'src/components/Icons/report';
import TransactionIcon from 'src/components/Icons/transaction';
import { ConfigContext } from 'src/contexts';
import { ROUTES } from 'src/helpers/constants';
import { StoreSelector, useStoreState } from 'src/States';
import { useMenuState } from 'src/States/Menu/menu';
import { useThermalPrinterState } from 'src/States/ThermalPrinter/ThermalPrinterState';
import { STORE_ATTRIBUTE_NAME } from 'src/submodules/sicpama-shared';

const translationPrefix = 'table-status';

interface NavbarLinkProps {
  readonly to: string;
  readonly Icon: FC<IconProps> | any;
  readonly text: string;
  readonly selected: boolean;
  readonly importedIcon?: boolean;
  readonly isExpanded?: boolean;
}

const StyledAccordion = styled(Accordion)`
  .mantine-Accordion-control {
    padding: 16px;
    font-size: 14px !important;
  }
  .mantine-Accordion-content {
    padding: 0 16px 16px 16px;
    font-size: 12px !important;
    opacity: 0.8;
  }
  .mantine-Accordion-label {
    padding: 0;
  }

  .mantine-Accordion-item {
    border-bottom: none;
  }
`;

function NavbarLink({
  to,
  Icon,
  text,
  selected,
  importedIcon,
  isExpanded = false,
}: NavbarLinkProps) {
  const backgroundIcon =
    selected === true ? 'bg-indigo-500 text-white rounded-lg' : 'bg-white text-gray-500';
  const iconColor = selected === true ? 'white' : '#5b5b5b';
  return (
    <NavLink
      to={to}
      className={`${backgroundIcon} w-full flex ${
        isExpanded ? 'flex-row' : 'flex-col'
      } items-center mb-8`}
    >
      <div className={`w-[30px] h-[30px] flex justify-center items-center rounded`}>
        {importedIcon ? Icon : <Icon color={iconColor} className=" h-[20px] w-[20px]" />}
      </div>
      <p className={`text-center font-medium ${isExpanded ? 'text-sm truncate' : 'text-xs'}`}>
        {text}
      </p>
    </NavLink>
  );
}

const Navbar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { storeData, isDesktopNavbarExpanded, setDesktopNavbarExpanded } = useStoreState();
  const inventoryEnabledAttr = useStoreState(
    StoreSelector.getAttribute(STORE_ATTRIBUTE_NAME.IS_INVENTORY_ENABLED),
  );
  const isInventoryEnabled = inventoryEnabledAttr?.value === 'true';
  const kdsHomePathAttr = useStoreState(
    StoreSelector.getAttribute(STORE_ATTRIBUTE_NAME.KDS_HOME_PAGE_PATH),
  );

  const kdsHomePath = kdsHomePathAttr?.value;

  const subscriptionEnabledAttr = useStoreState(
    StoreSelector.getAttribute(STORE_ATTRIBUTE_NAME.IS_SUBSCRIPTION_ENABLED),
  );
  const isSubscriptionEnabled = subscriptionEnabledAttr?.value === 'true';

  const mailOrderSalesRegistrationNumberAttr = useStoreState(
    StoreSelector.getAttribute(STORE_ATTRIBUTE_NAME.MAIL_ORDER_SALES_REGISTRATION_NUMBER),
  );

  const mailOrderRegistrationNumber =
    mailOrderSalesRegistrationNumberAttr?.value || '2022-대구동구-0174';

  const { setThermalPrinter } = useThermalPrinterState();
  const { changeAppLanguage, currentAppLanguage } = useContext(ConfigContext);

  const logoUrl = useMemo(() => {
    return storeData?.countryCode === 'KR'
      ? `${process.env.PUBLIC_URL}/assets/images/sicpamaLogo.png`
      : 'https://cdn.sicpama.com/sicpama-assets/raptor_logo.png';
  }, [storeData]);

  return (
    <div
      className={`flex flex-col h-screen z-[1000] shadow-xl bg-white border-r ${
        isDesktopNavbarExpanded ? 'w-52' : 'w-20'
      }`}
    >
      {/* Header */}
      <div
        className={`flex items-center ${
          isDesktopNavbarExpanded ? 'justify-start' : 'justify-center'
        } px-4 py-4 mb-4`}
      >
        <Icon
          name="Menu"
          onClick={() => setDesktopNavbarExpanded(!isDesktopNavbarExpanded)}
          color="black"
          size={28}
        />
        {isDesktopNavbarExpanded && (
          <div className={`ml-2 ${!isDesktopNavbarExpanded && 'hidden'}`}>
            <img className="h-4 w-auto object-contain" src={logoUrl} alt="SicpamaLogo" />
          </div>
        )}
      </div>
      <div className="flex-1 overflow-x-hidden px-4 no-scrollbar">
        <NavbarLink
          to={kdsHomePath ?? ROUTES.TABLE_STATUS}
          isExpanded={isDesktopNavbarExpanded}
          text={
            kdsHomePath && kdsHomePath !== ROUTES.TABLE_STATUS
              ? t(`${translationPrefix}.order-status`)
              : t(`${translationPrefix}.table-status`)
          }
          Icon={HomeIcon}
          selected={location.pathname.includes(kdsHomePath ?? ROUTES.TABLE_STATUS)}
        />
        <NavbarLink
          to={ROUTES.MENU_MANAGEMENT}
          text={t(`${translationPrefix}.product-management`)}
          Icon={MenuIcon}
          selected={location.pathname.includes(ROUTES.MENU_MANAGEMENT)}
          isExpanded={isDesktopNavbarExpanded}
        />
        <NavbarLink
          to={ROUTES.SALE_STATUS}
          text={t(`${translationPrefix}.sales-status`)}
          Icon={ReportIcon}
          selected={location.pathname.includes(ROUTES.SALE_STATUS)}
          isExpanded={isDesktopNavbarExpanded}
        />
        <NavbarLink
          to={ROUTES.TRANSACTION_HISTORY}
          text={t(`${translationPrefix}.transaction-history`)}
          Icon={TransactionIcon}
          selected={location.pathname.includes(ROUTES.TRANSACTION_HISTORY)}
          isExpanded={isDesktopNavbarExpanded}
        />
        <NavbarLink
          to={ROUTES.DATA_REPORT}
          text={t(`${translationPrefix}.data-report`)}
          Icon={DatabaseIcon}
          selected={location.pathname.includes(ROUTES.DATA_REPORT)}
          isExpanded={isDesktopNavbarExpanded}
        />
        <NavbarLink
          to={ROUTES.STORE_INFO}
          text={t('navigation_bar.setting')}
          isExpanded={isDesktopNavbarExpanded}
          Icon={
            <Icon
              name="Cog"
              size={28}
              type="outline"
              color={location.pathname.includes(ROUTES.STORE_INFO) ? 'white' : 'black'}
            />
          }
          importedIcon
          selected={location.pathname.includes(ROUTES.STORE_INFO)}
        />
        {isInventoryEnabled && (
          <NavbarLink
            to={ROUTES.INVENTORY_MANAGEMENT}
            text={t(`${translationPrefix}.inventory`)}
            Icon={InventoryIcon}
            selected={location.pathname.includes(ROUTES.INVENTORY_MANAGEMENT)}
            isExpanded={isDesktopNavbarExpanded}
          />
        )}
        <button
          className={`w-full flex ${
            isDesktopNavbarExpanded ? 'flex-row' : 'flex-col'
          } items-center pb-5`}
          onClick={setThermalPrinter}
        >
          <PrinterIcon color="#5b5b5b" className="w-[20px] h-[20px]" />
          <p
            className={`text-center ${
              isDesktopNavbarExpanded ? 'text-sm' : 'text-xs'
            } text-gray-500 ml-2`}
          >
            {t(`${translationPrefix}.connect-printer`)}
          </p>
        </button>
      </div>
      {storeData?.countryCode === 'KR' && isSubscriptionEnabled && isDesktopNavbarExpanded && (
        <div>
          <StyledAccordion
            items={[
              {
                value: '1',
                title: '식파마 사업자 정보',
                content: (
                  <p>
                    <div>대표자명: 서정환</div>
                    <div>사업자 등록번호: 863-88-02111</div>
                    <div>상호: 주식회사 식파마(Sicpama.Co.Ltd)</div>
                    <div>법인여부: 법인</div>
                    <div>통신판매번호: 2022-대구동구-0509</div>
                    <div>주소: 대구광역시 동구 동대구로 465, 6층</div>
                    <div>대표 전화번호: 070-8806-0927</div>
                    <div>이메일 : support@sicpama.com</div>
                  </p>
                ),
              },
            ]}
          />
          <StyledAccordion
            items={[
              {
                value: '1',
                title: `${storeData?.name} 사업자 정보`,
                content: (
                  <>
                    <div>상호명: {storeData?.name}</div>
                    {mailOrderRegistrationNumber && (
                      <div>통신판매업신고번호: {mailOrderRegistrationNumber}</div>
                    )}
                    {<div>대표자명: {storeData?.ownerName}</div>}
                    {<div>사업자등록번호: {storeData?.registrationNumber}</div>}
                    <div>사업장 주소: {storeData?.address}</div>
                    <div>유선번호: {storeData?.phoneNumber}</div>
                  </>
                ),
              },
            ]}
          />
        </div>
      )}
      <Select
        className="mb-4"
        data={[
          {
            value: 'en',
            label: `🇬🇧 ${isDesktopNavbarExpanded ? ' English' : ''}`,
          },
          {
            value: 'zh',
            label: `🇨🇳 ${isDesktopNavbarExpanded ? ' 简体中文' : ''}`,
          },
          {
            value: 'kr',
            label: `🇰🇷 ${isDesktopNavbarExpanded ? ' 한국어' : ''}`,
          },
        ]}
        onChange={(value: string) => {
          changeAppLanguage(value);
        }}
        value={currentAppLanguage}
      />
      <NavLink to={ROUTES.LOGIN} className="mt-auto mb-4">
        <p
          onClick={() => {
            localStorage.removeItem('ACCESS_TOKEN');
            localStorage.removeItem('STORE_ID');
            useMenuState.getState().clearState();
          }}
          className="text-center text-sm text-gray-500"
        >
          {t(`${translationPrefix}.log-out`)}
        </p>
      </NavLink>
    </div>
  );
};

export default Navbar;
