import styled from '@emotion/styled';
import { Icon, Select, Accordion } from '@sicpama/core-components';
import type { DrawerProps } from 'antd';
import { Divider, Drawer } from 'antd';
import React, { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import HomeIcon from 'src/components/Icons/home';
import { IconProps } from 'src/components/Icons/Icon.interface';
import MenuIcon from 'src/components/Icons/menu';
import PrinterIcon from 'src/components/Icons/printer';
import ReportIcon from 'src/components/Icons/report';
import TransactionIcon from 'src/components/Icons/transaction';
import { ConfigContext } from 'src/contexts';
import { ROUTES } from 'src/helpers/constants';
import { StoreSelector, useStoreState } from 'src/States';
import { useMenuState } from 'src/States/Menu/menu';
import { useThermalPrinterState } from 'src/States/ThermalPrinter/ThermalPrinterState';

import { STORE_ATTRIBUTE_NAME } from '../../submodules/sicpama-shared';
import DatabaseIcon from '../Icons/database';

interface SideNavigationProps {
  open: boolean;
  onClose: () => void;
  placement?: DrawerProps['placement'];
  width?: number | string;
}

interface NavbarLinkProps {
  readonly to: string;
  readonly Icon: FC<IconProps> | any;
  readonly text: string;
  readonly selected: boolean;
  readonly importedIcon?: boolean;
}

const translationPrefix = 'table-status';

const StyledAccordion = styled(Accordion)`
  .mantine-Accordion-control {
    padding: 8px;
    font-size: 14px !important;
  }
  .mantine-Accordion-content {
    padding: 0 8px 8px 8px;
    font-size: 12px !important;
    opacity: 0.8;
  }
  .mantine-Accordion-label {
    padding: 0;
  }

  .mantine-Accordion-item {
    border-bottom: none;
  }
`;

function NavbarLink({ to, Icon, text, selected, importedIcon }: NavbarLinkProps) {
  const backgroundIcon = selected === true ? 'bg-indigo-500' : 'transparent';
  const iconColor = selected === true ? 'white' : '#5b5b5b';
  return (
    <NavLink
      to={to}
      className="w-full max-w-[calc(100%-8px)] flex justify-center items-center mb-8 px-2"
    >
      <div
        className={`${backgroundIcon} w-[32px] h-[32px] rounded items-center justify-center flex-shrink-0`}
      >
        {importedIcon ? Icon : <Icon color={iconColor} className=" mx-auto" />}
      </div>
      <p className="font-medium text-sm text-gray-500 ml-2 break-words leading-tight flex-1">
        {text}
      </p>
    </NavLink>
  );
}

const SideNavigation: React.FC<SideNavigationProps> = ({
  open,
  onClose,
  placement = 'right',
  width = 204,
}) => {
  const { t } = useTranslation();
  const { setThermalPrinter } = useThermalPrinterState();
  const { changeAppLanguage, currentAppLanguage } = useContext(ConfigContext);

  const { storeData } = useStoreState();
  const { attributes } = storeData;
  const kdsHomePath = useMemo(() => {
    const kdsHomePathAttr = attributes?.find(
      (attr) => attr.name === STORE_ATTRIBUTE_NAME.KDS_HOME_PAGE_PATH,
    );
    return kdsHomePathAttr?.value ?? ROUTES.TABLE_STATUS;
  }, [attributes]);

  const subscriptionEnabledAttr = useStoreState(
    StoreSelector.getAttribute(STORE_ATTRIBUTE_NAME.IS_SUBSCRIPTION_ENABLED),
  );
  const isSubscriptionEnabled = subscriptionEnabledAttr?.value === 'true';

  const mailOrderSalesRegistrationNumberAttr = useStoreState(
    StoreSelector.getAttribute(STORE_ATTRIBUTE_NAME.MAIL_ORDER_SALES_REGISTRATION_NUMBER),
  );

  const mailOrderRegistrationNumber =
    mailOrderSalesRegistrationNumberAttr?.value || '2022-대구동구-0174';

  const logoUrl = useMemo(() => {
    return storeData?.countryCode === 'KR'
      ? `${process.env.PUBLIC_URL}/assets/images/sicpamaLogo.png`
      : 'https://cdn.sicpama.com/sicpama-assets/raptor_logo.png';
  }, [storeData]);

  return (
    <Drawer
      title={<img src={logoUrl} alt="SicpamaLogo" width={100} height={20} />}
      placement={placement}
      closable={false}
      onClose={onClose}
      open={open}
      key={placement}
      width={width}
      className="!bg-[#F6F6FF]"
      bodyStyle={{ paddingLeft: 4, paddingRight: 4 }}
    >
      <NavbarLink
        to={kdsHomePath}
        text={
          kdsHomePath && kdsHomePath !== ROUTES.TABLE_STATUS
            ? t(`${translationPrefix}.order-status`)
            : t(`${translationPrefix}.table-status`)
        }
        Icon={HomeIcon}
        selected={location.pathname.includes(kdsHomePath)}
      />

      <NavbarLink
        to={ROUTES.MENU_MANAGEMENT}
        text={t(`${translationPrefix}.product-management`)}
        Icon={MenuIcon}
        selected={location.pathname.includes(ROUTES.MENU_MANAGEMENT)}
      />

      <NavbarLink
        to={ROUTES.SALE_STATUS}
        text={t(`${translationPrefix}.sales-status`)}
        Icon={ReportIcon}
        selected={location.pathname.includes(ROUTES.SALE_STATUS)}
      />

      <NavbarLink
        to={ROUTES.TRANSACTION_HISTORY}
        text={t(`${translationPrefix}.transaction-history`)}
        Icon={TransactionIcon}
        selected={location.pathname.includes(ROUTES.TRANSACTION_HISTORY)}
      />

      <NavbarLink
        to={ROUTES.DATA_REPORT}
        text={t(`${translationPrefix}.data-report`)}
        Icon={DatabaseIcon}
        selected={location.pathname.includes(ROUTES.DATA_REPORT)}
      />

      <NavbarLink
        to={ROUTES.STORE_INFO}
        text={t('navigation_bar.setting')}
        Icon={
          <Icon
            name="Cog"
            size={28}
            type="outline"
            color={location.pathname.includes(ROUTES.STORE_INFO) ? 'white' : 'black'}
            style={{ marginLeft: 2, marginTop: 1 }}
          />
        }
        importedIcon
        selected={location.pathname.includes(ROUTES.STORE_INFO)}
      />

      <button
        className="w-full flex justify-start items-center pb-5 px-2"
        onClick={setThermalPrinter}
      >
        <PrinterIcon
          color="#5b5b5b"
          className="w-[32px] h-[32px] items-center justify-center flex-shrink-0"
        />
        <p className="text-sm text-gray-500 ml-2">{t(`${translationPrefix}.connect-printer`)}</p>
      </button>
      <Divider className="divider" />
      {storeData?.countryCode === 'KR' && isSubscriptionEnabled && (
        <div>
          <StyledAccordion
            items={[
              {
                value: '1',
                title: '식파마 사업자 정보',
                content: (
                  <p>
                    <div>대표자명: 서정환</div>
                    <div>사업자 등록번호: 863-88-02111</div>
                    <div>상호: 주식회사 식파마(Sicpama.Co.Ltd)</div>
                    <div>법인여부: 법인</div>
                    <div>통신판매번호: 2022-대구동구-0509</div>
                    <div>주소: 대구광역시 동구 동대구로 465, 6층</div>
                    <div>대표 전화번호: 070-8806-0927</div>
                    <div>이메일 : support@sicpama.com</div>
                  </p>
                ),
              },
            ]}
          />
          <StyledAccordion
            items={[
              {
                value: '1',
                title: `${storeData?.name} 사업자 정보`,
                content: (
                  <>
                    <div>상호명: {storeData?.name}</div>
                    {mailOrderRegistrationNumber && (
                      <div>통신판매업신고번호: {mailOrderRegistrationNumber}</div>
                    )}
                    {<div>대표자명: {storeData?.ownerName}</div>}
                    {<div>사업자등록번호: {storeData?.registrationNumber}</div>}
                    <div>사업장 주소: {storeData?.address}</div>
                    <div>유선번호: {storeData?.phoneNumber}</div>
                  </>
                ),
              },
            ]}
          />
        </div>
      )}
      <Divider className="divider" />
      <Select
        className="mb-4"
        data={[
          {
            value: 'en',
            label: '🇬🇧  English',
          },
          {
            value: 'zh',
            label: '🇨🇳  简体中文',
          },
          {
            value: 'kr',
            label: '🇰🇷  한국어',
          },
        ]}
        onChange={(value: string) => {
          changeAppLanguage(value);
        }}
        value={currentAppLanguage}
      />
      <NavLink to={ROUTES.LOGIN} className="mt-auto mb-4">
        <p
          onClick={() => {
            localStorage.removeItem('ACCESS_TOKEN');
            localStorage.removeItem('STORE_ID');
            useMenuState.getState().clearState();
          }}
          className="text-center text-sm text-gray-500"
        >
          {t(`${translationPrefix}.log-out`)}
        </p>
      </NavLink>
    </Drawer>
  );
};

export default SideNavigation;
