/* eslint-disable @typescript-eslint/no-unused-vars */
import { notifications } from '@sicpama/core-components/lib/notifications';
import { Modal, Switch } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useChangeBusinessAvailability from '../../hooks/useChangeBusinessAvailability';

import './styles/CloseBusiness.scss';

const CloseBusiness = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { isOpen, changeAvailability } = useChangeBusinessAvailability();

  const toggleStoreOpenStatus = async (status: boolean) => {
    if (isEditing) return;
    setIsEditing(true);
    try {
      await changeAvailability({ isClosed: !status, callback: () => setOpenModal(false) });
    } catch (error) {
      console.error(error);
      notifications.show({
        withCloseButton: true,
        autoClose: 2000,
        message: error?.message || JSON.stringify(error),
        color: 'red',
      });
    } finally {
      setIsEditing(false);
    }
  };

  const containerClassName = useMemo(() => {
    return isOpen ? 'open-business-switch-container' : 'close-business-switch-container';
  }, [isOpen]);

  return (
    <>
      <Switch
        checked={isOpen}
        checkedChildren={
          <span className="text-black text-[14px] leading-[24px]">{t('food-truck.open')}</span>
        }
        unCheckedChildren={
          <span className="text-black text-[14px] leading-[14px]">{t('food-truck.close')}</span>
        }
        className={`${containerClassName} !bg-white [&_[class*='ant-switch-inner-unchecked']]:!mt-[-20px] [&_[class*='ant-switch-handle']]:!start-[calc(100%-24px)] [&_[class*='ant-switch-handle']]:top-[5px] [&_[class*='ant-switch-inner']]:[padding-inline-start:0px] shadow h-7 w-[88px] !rounded-[10px]`}
        onClick={() => {
          if (isOpen) {
            setOpenModal(true);
          }
          if (!isOpen) {
            toggleStoreOpenStatus(true);
          }
        }}
      />
      <Modal
        open={!!openModal}
        footer={null}
        centered
        onCancel={() => setOpenModal(false)}
        className="[&_[class*='ant-modal-content']]:!p-0"
      >
        <div className="pt-12 pb-8 px-[10px]">
          <p className="text-center font-bold leading-[24px] whitespace-pre-line">
            {t('food-truck.close-business-statement')}
          </p>
        </div>
        <div className="w-full grid grid-cols-2 h-14 rounded-t-[8px] overflow-hidden">
          <div
            className={`text-white flex items-center justify-center h-full bg-[#FC5C0C] text-[18px] ${
              isEditing && 'opacity-50'
            }`}
            onClick={() => toggleStoreOpenStatus(false)}
          >
            {t('food-truck.close-business')}
          </div>
          <div
            className="text-white flex items-center justify-center h-full bg-[#9a9a9a] text-[18px]"
            onClick={() => setOpenModal(false)}
          >
            {t('food-truck.keep-open')}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CloseBusiness;
